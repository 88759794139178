<template>
    <div class="remark-main" v-if="show">
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <div class="close" @click="closePop">
            <i class="el-icon-close"></i>
        </div>
        <div class="title">
            智能看护
        </div>
        <div class="info">
            老人姓名：{{info.personIdText}}
        </div>
        <div class="info">
            联系电话：{{info.phone || '暂无'}}
        </div>
        <div class="info">
            家庭地址：{{info.detailAddress}}
        </div>
        <!-- 切换 -->
        <div class="option">
            <div :class="optionType == 1 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(1)">预警记录</div>
            <div :class="optionType == 2 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(2)">设备状态</div>
        </div>
        <div v-if="optionType == 1" class="option-one" :style="{'height': maxHeight + 'px'}">
             <div class="item-main-top">
            <div class="item-main-top-item">
              <el-date-picker
                v-model="warningTime"
                type="daterange"
                range-separator="预警时间"
                clear-icon
                >
              </el-date-picker>
            </div>
            
            <div class="item-main-top-item">
              <el-select
               v-model="warningType"
               placeholder="预警类型"
               style="width:100%;"
               @change="option"
               >
                <el-option v-for="(item,index) in typeList" :key="index" :value="item" :label="item"></el-option>
              </el-select>
            </div>
            <div class="item-main-top-item">
              预警内容
            </div>
            <div class="item-main-top-item">
              <el-select
               v-model="warningStatus"
               placeholder="处理状态"
               style="width:100%;"
               @change="option"
               >
                <el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </div>
          </div>
          <div class="item-main-info">
            <div @click="optioncommunity(item.roomAddress)" v-for="(item,index) in warningHandingDetails" :key="index" class="item-main-info-item">
              <div>{{item.alarmTime.substr(5, 11)}}</div>
              <div>{{item.subName}}</div>
              <div>{{item.alarmType}}</div>
              <div :class="item.processStatus == -1 ? 'unhandled' : item.processStatus == 0 ? 'pending' : '' ">{{item.processStatusText}}</div>
            </div>
          </div>
        </div>
        <div v-if="optionType == 2" class="option-two" :style="{'height': maxHeight + 'px'}">
            <div class="option-item" v-for="(item, idx) in deviceList" :key="idx">
                <img class="device-image" :src="getImageUrl(item.deviceType)" alt="">
                <div class="option-item-name">
                    {{item.name}}
                </div>
                <div class="option-item-status">
                    <span></span>
                    <span>{{item.olStatus==0?'离线':'在线'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {personEventList, personDeviceList} from "../api/indexApi";
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            optionType: 2,
            warningTime: '',
            warningPosition: '',
            positionList: [],
            warningType: '',
            typeList: [],
            warningStatus: '',
            statusList: [],
            warningHandingDetails: [
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容,这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容,这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容,这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容,这是预警内容',status: 0,},
                {time: '2023-3-22',warningType: 'SOS报警',content: '这是预警内容',status: 0,},
            ],
			deviceList: [],
            maxHeight: 0,
            info: {},
        }
    },
    created() {
        this.maxHeight = document.documentElement.clientHeight - 400
    },
    methods: {
        optionClick(val) {
            this.optionType = val
        },
        closePop() {
            this.$emit('update:show',false)
        },
        option() {},
        optioncommunity() {},
		init(personId, gwMac,info) {
            // 单个老人预警记录	
            this.info = info					
            personEventList({
                'page': 1,
                'limit': 10,
                'personId': personId
            }).then(res => {
                if (res.code == 0) {
                    this.warningHandingDetails = res.page.list
                }else {
                    this.warningHandingDetails = []
                }
            })
            // 单个老人设备状态
            personDeviceList(gwMac).then(res => {
                if (res.code == 0) {
                    this.deviceList = res.list
                }else {
                    this.deviceList = []
                }
            })
		},
				getImageUrl(deviceType) {
					let imageUrl = '/image/equipment/gw.jpg'
					if (deviceType == '17') {
						imageUrl = '/image/equipment/men.png'
					} else if (deviceType == '18') {
						imageUrl = '/image/equipment/water.png'
					} else if (deviceType == '19') {
						imageUrl = '/image/equipment/redline.png'
					} else if (deviceType == '20') {
						imageUrl = '/image/equipment/gas.png'
					} else if (deviceType == '49') {
						imageUrl = '/image/equipment/sos.png'
					} 
					return imageUrl
				}
    },
}
</script>
<style lang="scss">
    .remark-main {
        width: 500px;
        // height: 100px;
        background: rgba(1,31,17,.8);
        position: absolute;
        border: 1px solid #2BCEFD;
        box-sizing: border-box;
        padding: 10px;
        top: 200px;
        left: calc(50% - 250px);
        z-index: 999;
        .border-color:nth-child(1),.border-color:nth-child(2), .border-color:nth-child(3){
            position: absolute;
            // top: 0;
            left: -2px;
            display: block;
            // height: 60px;
            width: 4px;
            background: #2BCEFD ;
        }
        .border-color:nth-child(1) {
            height: 60px;
            top: 0;
        }
        .border-color:nth-child(2) {
            height: 30px;
            top: 90px;
        }
        .border-color:nth-child(3) {
            height: 60px;
            bottom: 0;
        }
        .border-color:nth-child(4) {
            display: block;
            background: #2BCEFD;
            height: 4px;
            width: 30px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .close {
            display: block;
            position: absolute;
            background: rgba(40,70,140,.8);
            top: -20px;
            right: -2px;
            color: white;
            cursor: pointer;
        }
        .title {
            width: 80%;
            background: rgb(15,135,255);
            color: white;
            text-align: left;
            box-sizing: border-box;
            padding: 5px;
            padding-left: 10px;
        }
        .info {
            width: 100%;
            color: white;
            font-size: 12px;
            box-sizing: border-box;
            padding: 10px 10px 0 10px;
            // padding-left: ;
            text-align: left;
        }
        .option {
            display: flex;
            margin-top: 20px;
            .option-item {
                font-size: 12px;
                color: #fff;
                padding: 5px 10px;
                background: rgba(0,131,255,.23);
            }
            .option-item-active {
                border: 2px solid rgba(15,135,255,.8);
            }
        }
        .option-one {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            height: calc(100% - 40px);
            box-sizing: border-box;
            flex-wrap: wrap;
            padding: 40px 10px;
            padding-bottom: 30px;
            background: rgba(0,131,255,.23);
            .item-main-top {
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          height: 30px;
          justify-content: space-between;
          .item-main-top-item {
            width: 24%;
            background: rgba(0,86,255,.6);
            font-size: 12px;
            display: flex;
            align-items: center;
            color: white;
            justify-content: center;
            .el-select {
                .el-input {
                    .el-input__inner {
                        background: none;
                        color: white;
                        text-align: center;
                        border: none;
                        padding-left: 5px;
                        padding-right: 15px;
                        font-size: 12px;
                    }
                    .el-input__suffix {
                        right: -2px;
                    }
                }
                input::-webkit-input-placeholder{
                    color:#fff;
                }
                input::-moz-placeholder{
                    color:#fff;
                }
                input:-ms-input-placeholder{
                    color:#fff;
                }
            }
            .el-date-editor {
                .el-range-separator {
                    color: #fff;
                }
            }
          }
        }
        .item-main-info {
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px ;
          padding-top: 10px;
          // height: calc(100% - 18% - 10px);
          height: 100%;
          overflow: auto;
          scrollbar-width: none;
          max-height: 310px;
          &::-webkit-scrollbar {
            display: none;
          }
          .item-main-info-item {
            height: 30px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            -ms-overflow-style: none;
            div {
              width: 24%;
              font-size: 12px;
              // display: flex;
              // align-items: center;
              color: white;
              // justify-content: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 20px;
              cursor: pointer;
            }
            .unhandled {
              color: red;
            }
            .pending {
              color: rgb(15,135,255);
            }
          }
          .item-main-info-item:nth-child(2n) {
              background: rgba(0,131,255,.25);
          }
        }
        }
        .option-two {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
            padding: 40px 10px;
            padding-bottom: 30px;
            background: rgba(0,131,255,.23);
            overflow: auto;
            .option-item {
                background: #fff;
                border-radius: 10px;
                padding: 20px;
                width: 32%;
                margin-right: 2%;
                margin-bottom: 10px;
                color: #333;
                box-sizing: border-box;

                .option-item-name {
                    margin-top: 5px;
                    font-size: 14px;
                    text-align: left;
                    font-weight: bold;
                }
                .option-item-status {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #999;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    span:nth-child(1) {
                        display: inline-block;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background: green;
                        margin-right: 10px;
                    }
                }
            }
            .option-item:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
		.device-image {
			width: 80px;
			height: 80px;
		}
</style>