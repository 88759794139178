<template>
  <div class="main">
    <div class="main-top">
      <div class="main-top-title">
        <!-- 民政养老服务管理系统 -->
        <img src="../image/logo2.png" alt="" />
      </div>
      <div class="main-top-option">
        <!-- <div :class="topOption == 1 ? 'top-option-item option-active' : 'top-option-item'" @click="topOptionClick(1)">智能看护</div>
        <div :class="topOption == 2 ? 'top-option-item option-active' : 'top-option-item'" @click="topOptionClick(2)">养老助餐</div>
        <div :class="topOption == 3 ? 'top-option-item option-active' : 'top-option-item'" @click="topOptionClick(3)">关怀探访</div> -->
      </div>
      <div class="main-top-time">
        <div class="newTime">
          <div>
            {{ newDay }}
            <span style="margin-left: 10px">{{ newTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-left">
      <div class="item" style="max-height: 200px;">
        <div class="item-title">设备安装概况</div>
        <div class="item-content-left">
          <polyline-chart					 
						type="pie" :pieRadius="['60%','75%']" simple
						:color="['#1363EB', '#909399']"
						:series="[{name: '已安装', value: setupReportData.setupTotal},{name: '待安装', value: setupReportData.waiting}]">
          </polyline-chart>

        </div>
        <div class="item-content-right">
          <div class="item-content-right-item">
            <div style="padding-top: 5px">
              <p class="type">待安装老人</p>
              <p>...</p>
            </div>
            <div style="padding-bottom: 5px">
              <p class="num">{{setupReportData.waiting}}<span>户</span></p>
              <p>{{Math.round((setupReportData.waiting / setupReportData.total)*100)}}%</p>
            </div>
          </div>
          <div class="item-content-right-item">
            <div style="padding-top: 5px">
              <p class="type">已安装老人</p>
              <p>...</p>
            </div>
            <div style="padding-bottom: 5px">
              <p class="num">{{setupReportData.setupTotal}}<span>户</span></p>
              <p>{{Math.round((setupReportData.setupTotal / setupReportData.total)*100)}}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-title">设备在线率统计</div>
        <div class="item-chart">
          <polyline-chart overlay
            type="bar" yName="个数"
						:xData="onlineReportData.xData"
						:series="onlineReportData.series"
          ></polyline-chart>
        </div>
      </div>
      <div class="item frequency">
        <div class="item-title">报警频次统计</div>
        <div class="item-chart">
          <div class="checked-box">
            <el-radio-group fill="#0083FF" size="mini" v-model="checkboxGroup1" @input="getAlarmReportData">
              <el-radio-button
                v-for="time in lookTime"
                :label="time.id"
                :key="time.id"
                >{{ time.title }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <polyline-chart
            type="line" yName="次数"
						:xData="alarmReportData.xData"
						:series="alarmReportData.series"
          ></polyline-chart>
        </div>
      </div>
    </div>
    <div class="main-right">
      <div class="item" style="height: 22%">
        <div class="item-title">今日事件总览</div>
        <div class="item-main">
          <div class="item-main-item">
            <span class="item-main-item-top">今日事件</span>
            <p class="item-main-item-bottom">{{todayEventData.total}}</p>
          </div>
          <div class="item-main-item">
            <span class="item-main-item-top">已通知</span>
            <p class="item-main-item-bottom">{{todayEventData.ended}}</p>
          </div>
          <div class="item-main-item">
            <span class="item-main-item-top">待通知</span>
            <p class="item-main-item-bottom">{{todayEventData.waiting}}</p>
          </div>
        </div>
      </div>
      <div class="item" style="height: 36%">
        <div class="item-title">报警类型占比</div>
        <div class="item-chart">
          <polyline-chart
            yName="预警量"
            type="rosePie" :pieRadius="['10%','75%']"
						:series="eventTypeReportData"
          >
          </polyline-chart>
        </div>
      </div>
      <div class="item" style="height: 41%; overflow: auto;" >
        <div class="item-title">报警记录</div>
        <div class="item-main" style="padding-top: 0px; cursor: pointer;">
					<el-table
					    ref="filterTable"
					    :data="eventListData"
					    style="width: 100%">
					    <el-table-column width="100"
					      prop="alarmTime"
					      label="时间"
					      :filters="[]"
					      :filter-method="filterHandler"
					    >
							<template slot-scope="scope">
								{{scope.row.alarmTime.substr(5, 11)}}
							</template>
					    </el-table-column>
					    <el-table-column
					      prop="personIdText"
					      label="老人">
					    </el-table-column>
					    <el-table-column show-overflow-tooltip
					      prop="detailAddress"
					      label="区域"
								:filters="regionList">
					    </el-table-column>
					    <el-table-column
					      prop="alarmType"
					      label="预警类型"
					      :filters="[]"
					      :filter-method="filterHandler"
					      filter-placement="bottom-end">
					    </el-table-column>
					  </el-table>
        </div>
      </div>
    </div>
    <div id="allmap">
      <!-- 详情弹窗 -->
      <!-- <remarker ref="remarkerDetails" :show.sync="detailsShow"></remarker> -->
      <!-- 报警弹窗 -->
      <!-- <marker-warning :show.sync="warningShow" ref="markerWarning" @processEnded="processEnded"></marker-warning> -->
    </div>
    <!-- 详情弹窗 -->
    <remarker ref="remarkerDetails" :show.sync="detailsShow"></remarker>
    <!-- 报警弹窗 -->
    <marker-warning :show.sync="warningShow" ref="markerWarning" @processEnded="processEnded"></marker-warning>
  </div>
</template>
<script>
import polylineChart from "../components/polyline-chart.vue";
import weather from "../components/weather.vue";
import remarker from "../components/remarker.vue";
import markerWarning from "../components/marker-warning.vue";
import {setupReport, onlineReport, alarmReport, todayEvent, eventTypeReport,eventList,personList} from "../api/indexApi";
export default {
  components: {
    polylineChart,
    weather,
    remarker,
    markerWarning,
  },
  data() {
    return {
      map: "",
      // 时间
      newDay: "2022.10.23",
      newTime: "18:25:53",
      popShow: false,
      // 顶部内容切换
      topOption: 1,
      // 右侧预警记录
      warningTime: "",
      warningPosition: "",
      positionList: [],
      warningType: "",
      typeList: [],
      warningStatus: "",
      statusList: [],
      warningHandingDetails: [],
      // 中间区域查询
      area: "",
      areaList: [],
      town: "",
      townList: [],
      // 预警弹窗
      warningShow: false,
      // 详情弹窗
      detailsShow: false,
      // 刷新时间
      dateTimer: null,

      checkboxGroup1: "day",
      lookTime: [
        { title: "近24小时", id: 'day' },
        { title: "近一周", id: 'week' },
      ],
			setupReportData: {},
			onlineReportData: {
				xData: [],
				series: []
			},
			alarmReportData: {
				xData: [],
				series: []
			},
			todayEventData: {},
			eventTypeReportData: [],
			eventListData: [],
			regionList: [],
			personListData: []
			
    };
  },
  computed: {},
  watch: {
    "$store.state.commom.remarkStatus": {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch("commom/setRemarkStatus", false);
        }
      },
    },
  },
  created() {
    setTimeout(() => {
      this.$topGetToken(token=>{
        console.log('========')
        console.log(token)
      })
    }, 3000)
    setTimeout(() => {
      this.$topGetBigScreenPermissions(pers=>{
      })
    }, 5000)


    this.m_mapLoader().then((AMap) => {
      this.initMap(this.$mapStyle);
			this.getPersonList()
      // this.getPosition();
    });
    this.dateTimer = setInterval(() => {
      this.getData();
    }, 500);
		
		this.getSetupReport()
		this.getOnlineReportData()
		this.getAlarmReportData()
		this.getTodayEventData()
		this.getEventTypeReportData()
		// this.getEventListData()
  },
  methods: {
    // 获取生成地标位置信息
    addMarker(obj) {
      // var obj = { position: [104.566034, 30.402503], name: "张三" };
      this.m_addMarker(this.map, obj, () => {
        this.detailsShow = true;
      });
    },
    // 顶部内容选择
    topOptionClick(val) {
      this.topOption = val;
    },
    getData() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let ms =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      this.newDay = yy + "." + mm + "." + dd;
      this.newTime = hh + ":" + mf + ":" + ms;
    },
    initMap(mapStype, center, zoom) {
      let map = this.m_initMap(this.$mapStyle);
      map.on("click", (e) => {
        console.log("click", e);
      });
      this.map = map;
			this.getEventListData()
			
    },
    option() {},
    optioncommunity() {},
		//设备安装概况
		getSetupReport() {
			setupReport().then(res => {
				if (res.code == 0) {
					this.setupReportData = res.data
				}
			})			
		},
		// 设备在线率统计
		getOnlineReportData() {
			onlineReport().then(res => {
				if (res.code == 0) {
					let list = res.list
					let xData = []
					let online = []
					let offline = []
					let total = []
					list.forEach(item => {
						xData.push(item.name)
						online.push(item.online)
						offline.push(item.offline)
						total.push(item.online + item.offline)
					})
					this.onlineReportData.xData = xData
					this.onlineReportData.series.push({name: '在线', data: online, type: 'bar'})
					this.onlineReportData.series.push({name: '离线', data: offline, type: 'bar'})
					this.onlineReportData.series.push({name: '总数', data: total, type: 'line'})
				}
			})
		},
		// 报警频次统计
		getAlarmReportData() {
			this.alarmReportData = []
			alarmReport(this.checkboxGroup1).then(res => {
				if (res.code == 0) {
					let data = res.data
					this.alarmReportData.xData = data.time
					data.dataList.forEach(item => {
						item.data = item.alarmCount
					})
					this.alarmReportData.series = data.dataList
				}
			})
		},
		// 今日事件
		getTodayEventData() {
			todayEvent().then(res => {
				if (res.code == 0) {
					this.todayEventData = res.data
				}
			})
		},
		// 报警类型占比
		getEventTypeReportData() {
			eventTypeReport().then(res => {
				if (res.code == 0) {
					let list = res.list
					list.forEach(item => {
						item.value=item.total
					})
					this.eventTypeReportData = list
				}
			})
		},
		// 报警记录
		getEventListData() {
			eventList({
				page: 1, 
				limit: 10,
				status: 0
			}).then(res => {
				if (res.code == 0) {
					this.eventListData = res.page.list
					
					// 添加marker
					this.eventListData.forEach(item => {
						let obj = {}
						obj.num = 1
						obj.name=item.personIdText
						obj.data = item
						if (!item.gwLng || !item.gwLat) {
							this.m_addressPosition(this.map, obj, item.detailAddress).then(data => {
								this.m_addMarker(this.map, obj, (element) => {
									this.warningShow = true
									this.$refs.markerWarning.init(element)
								});
							})
						}else {
							obj.position = [item.gwLng, item.gwLat]							
							this.m_addMarker(this.map, obj, (element) => {
								this.warningShow = true
								this.$refs.markerWarning.init(element)
							});
						}
					})

				}
			})
		},
		// 已安装老人
		getPersonList() {
			personList().then(res => {
				if (res.code == 0) {
					this.personListData = res.list
					let points = []
					this.personListData.forEach(item => {
						if (item.lng && item.lat) {
							points.push({
								lnglat: [item.lng, item.lat],
								data: item
							})
						}
					})
					this.m_addMarkerCluster(this.map, points, (data) => {
						this.detailsShow = true
						// 单个老人预警记录
						// 单个老人设备状态
						this.$refs.remarkerDetails.init(data.personId, data.gwMac,data)
					})
				}
			})
		},
		processEnded(data) {
			console.log('processEnded', data)
			this.m_clearWarningMarker(this.map)
			this.getEventListData()
			this.warningShow = false
		},
		filterHandler(value, row, column) {
			
		},
		
  },
};
</script>
<style lang="scss">
@import "../assets/css/index.scss";
@import "../assets/css/element-ui.css";
</style>

