<template>
    <div class="marker-warning" v-if="show">
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <div class="close" @click="closePop">
            <i class="el-icon-close"></i>
        </div>
        <div class="title">预警处理</div>
        <div class="warning-title">{{eventData.personIdText}}</div>
        <div class="warning-info">联系电话：{{eventData.phone}}</div>
        <div class="warning-info">家庭地址：简阳市{{eventData.detailAddress}}</div>
        <div class="warning-info">预警时间：{{eventData.alarmTime}}</div>
        <div class="warning-info">预警类型：<span style="color:#F10808">{{eventData.subName}}预警</span></div>
        <div class="warning-info">预警内容：{{eventData.alarmType}}</div>
        <div class="warning-status">
            <div class="status-item">预警状态：</div>
            <div v-if="idx == 1" class="status-option">
                <!-- <div :class="statusOption == 1 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(1)">暂不处理</div> -->
                <div :class="statusOption == 2 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(2)">待处理</div>
                <div :class="statusOption == 3 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(3)">已处理</div>
            </div>
            <div v-else class="status-option">
                <div :class="statusOption == 1 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(1)">暂不处理</div>
                <div :class="statusOption == 2 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(2)">处理中</div>
                <div :class="statusOption == 3 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(3)">已处理</div>
            </div>
            <div class="status-item">处理说明：</div>
            <textarea v-model="remarks" placeholder="请描写处理情况" class="status-content" :disabled="!dealShow"></textarea>
        </div>
        <div class="submit" v-if="dealShow" @click="saveResult">完成</div>
    </div>
</template>
<script>
import {processEvent} from "../api/indexApi";
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            statusOption: 0,
			remarks: '',
			eventData: {},
            idx: 0,
            dealShow: true,
        }
    },
    methods: {
        optionClick(val) {
            if(!this.dealShow) return
            this.statusOption = val
        },
        closePop() {
            // this.show = false
            this.$emit('update:show',false)
        },
        init(data,idx) {
            this.eventData = data
            this.dealShow = true
            this.remarks = ''
            if(idx) {
                this.idx = idx
                if(data.status == 0) {
                    this.statusOption = 2
                }else {
                    this.statusOption = 3
                    this.remarks = data.remarks
                    this.dealShow = false
                }
            }else {
                this.idx = 0
            }
        },
        saveResult() {
            if(this.idx == 1) {
                if (this.statusOption <= 2) {
                    this.$message.error('请先选择处理状态');
                    return
                }
                setTimeout(() => {
                    this.$message.success('预警信息已处理')
                    this.$emit('processEnded', this.remarks)
                },500)
            }else {
                if (this.statusOption <= 0) {
                    this.$message.error('请先选择处理状态');
                    return
                }
                // 保存处理结果
                processEvent({
                    id: this.eventData.id,
                    status: this.statusOption,
                    remark: this.remarks
                }).then(res => {
                    if (res.code == 0) {
                        this.$message.success('预警信息已处理')
                        this.$emit('processEnded', this.eventData)
                    }
                })
            }
            
        }
    },
}
</script>
<style lang="scss">
    .marker-warning {
        width: 500px;
        // height: 100px;
        background: rgba(1,31,17,.8);
        position: absolute;
        border: 1px solid #F10808;
        box-sizing: border-box;
        padding: 10px;
        top: 200px;
        left: calc(50% - 250px);
        z-index: 999;
        .border-color:nth-child(1),.border-color:nth-child(2), .border-color:nth-child(3){
            position: absolute;
            // top: 0;
            left: -2px;
            display: block;
            // height: 60px;
            width: 4px;
            background: #F10808 ;
        }
        .border-color:nth-child(1) {
            height: 60px;
            top: 0;
        }
        .border-color:nth-child(2) {
            height: 30px;
            top: 90px;
        }
        .border-color:nth-child(3) {
            height: 60px;
            bottom: 0;
        }
        .border-color:nth-child(4) {
            display: block;
            background: #F10808;
            height: 4px;
            width: 30px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .close {
            display: block;
            position: absolute;
            background: #F10808;
            top: -20px;
            right: -2px;
            color: white;
            cursor: pointer;
        }
        .title {
            width: 80%;
            background: #F10808;
            color: white;
            text-align: left;
            box-sizing: border-box;
            padding: 5px;
            padding-left: 10px;
        }
        .warning-title {
            width: 100%;
            color: white;
            font-size: 18px;
            box-sizing: border-box;
            padding: 15px 10px 0 20px;
            // padding-left: ;
            text-align: left;
        }
        .warning-info {
            width: 100%;
            color: white;
            font-size: 12px;
            box-sizing: border-box;
            padding: 15px 10px 0 20px;
            // padding-left: ;
            text-align: left;
        }
        .warning-status {
            width: 100%;
            background: rgba(241,8,8,.2);
            padding: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            .status-item {
                color: #fff;
                font-size: 12px;
                text-align: left;
                margin-bottom: 10px;
            }
            .status-option {
                width: 100%;
                display: flex;
                margin-bottom: 10px;
                .option-item {
                    width: 80px;
                    text-align: center;
                    padding: 5px 0;
                    border: 1px solid #fff;
                    color: #fff;
                    margin-right: 20px;
                    font-size: 12px;
                    cursor: pointer;
                }
                .option-item-active {
                    border: 1px solid #F10808;
                    color: #F10808;
                }
            }
            .status-content {
                width: 100%;
                height: 50px;
                outline: none;
                padding: 5px;
                box-sizing: border-box;
            }
        }
        .submit {
            width: 40%;
            text-align: center;
            padding: 5px 0;
            background: #F10808;
            color: #fff;
            font-size: 16px;
            margin-left: 30%;
            margin-top: 20px;
            margin-bottom: 30px;
            cursor: pointer;
        }
    }
</style>