<template>
  <div>
    <div id="weather-v2-plugin-simple"></div>
  </div>
</template>

<script>
export default {
  created(){
    window.WIDGET = {
      CONFIG: {
          "modules": "01234",
          "background": 5,
          "tmpColor": "FFFFFF",
          "tmpSize": 16,
          "cityColor": "FFFFFF",
          "citySize": 16,
          "aqiColor": "FFFFFF",
          "aqiSize": 16,
          "weatherIconSize": 24,
          "alertIconSize": 18,
          "padding": "10px 0px 10px 10px",
          "shadow": "0",
          "language": "auto",
          "borderRadius": 5,
          "fixed": "false",
          "vertical": "middle",
          "horizontal": "right",
          "key": "2j6EKqRbiD"
        }
    };
    ( (d) => {
      var c = d.createElement('link')
      c.rel = 'stylesheet'
      c.href = 'https://apip.weatherdt.com/simple/static/css/weather-simple.css?v=2.0'
      var s = d.createElement('script')
      s.src = 'https://apip.weatherdt.com/simple/static/js/weather-simple.js?v=2.0'
      var sn = d.getElementsByTagName('script')[0]
      sn.parentNode.insertBefore(c, sn)
      sn.parentNode.insertBefore(s, sn)
    })(document);
  }
}
</script>